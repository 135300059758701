<template>
  <CoursesManagementComponent />
</template>

<script>
import CoursesManagementComponent from '@/components/courses-management/CoursesManagementComponent.vue'

export default {
  name: 'CoursesManagement',
  components: {
    CoursesManagementComponent
  }
}
</script>
